import './src/assets/styles/global.css'
import './src/assets/styles/tailwindcss-global.css'
import 'firebase/firestore'
import mixpanel from 'mixpanel-browser'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
  once: true,
})

export const onInitialClientRender = () => {
  setTimeout(() => {
    const userId = window?.user?.uid
    if (userId) {
      const phoneNumber = window?.user?.phoneNumber
      mixpanel.register({
        userId,
        Phone: phoneNumber,
        Platform: 'app',
      })
      mixpanel.identify(phoneNumber)
      mixpanel.people.set_once({
        userId,
        $CleverTap_user_id: phoneNumber,
        $phone: phoneNumber,
      })
    }
  }, 3000)
}

export const onClientEntry = () => {
  mixpanel.init(process.env.GATSBY_MIXPANEL_KEY)
  const isWebView = !!window?.ReactNativeWebView
  mixpanel.register({
    Platform: isWebView ? 'app' : 'web',
  })
}
